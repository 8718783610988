import Repository from "./Repository";
import TokenService from "../helpers/token";
const resource = `${Repository.defaults.erpUrl}`;
const config = {
  headers: TokenService.getHeader(),
};

export default {
  async getSchoolLevels() {
    return await Repository.get(`${resource}/school-levels`, config);
  },
  async getProducts(params) {
    return await Repository.get(`${resource}/product-group?limit=9999&status=active&page=0&keyword=` , {params, ...config});
  },

  async getTypes() {
    return await Repository.get(`${resource}/type`, config);
  },

  async getUsers() {
    return await Repository.get(`${resource}/bitu/user`, config);
  },

  async getUserSale() {
    return await Repository.get(`${resource}/user/list-user/by-permissions?permissions[]=take-care-contact__read&permissions[]=bitu-chat-advise__read`, config)
  }
};
